@charset "utf-8";
/* CSS Document for Header, Main Nav Bar, and Footer */
/* =============================================================================
   Header Bar
   ========================================================================== */
.headerbar {
    color: #fff;
    position: relative;
}
.headerbar.pinned {
    min-height: 47px;
}
@media screen and (min-width: 48em) {
    .headerbar.pinned {
        min-height: 52px;
    }
}
@media screen and (min-width: 60em) {
    .headerbar.pinned {
        min-height: 57px;
    }
}
.headerbar, .headerbar > .headerbar-wrapper {
    background: #0054A0;
}
.headerbar > .headerbar-wrapper {
    border-bottom: 5px solid #0da2d4;
}
.headerbar > .headerbar-wrapper.no-border {
    border-bottom: 0px transparent
}
.pinned > .headerbar-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}
/* only limit the width for pages that follow the max-width of 1700px. Old legacy sections do not have the width limit */
#container .pinned > .headerbar-wrapper {
    max-width: 1700px;
}
@media screen and (max-width: 47.9375em) {
    #princess-header { 
        overflow: hidden;
    }
    .headerbar > .headerbar-wrapper {
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1000;
    }
}
.fixed > .headerbar-wrapper {
    position: fixed;
    transition: top .75s;
}
@media screen and (min-width: 106.25em) {
    /* only limit the width for pages that follow the max-width of 1700px. Old legacy sections do not have the width limit */
    #container .fixed > .headerbar-wrapper {
        left: 50%;
        margin-left: -850px;
    }
}
.headerbar button {
    background: transparent;
    border: none;
    margin: 0;
}
.headerbar button:focus {
    outline-offset: -2px;
    box-shadow: inset 0 0 0 2px rgba(0,0,0,.8);
}


/*
 * Header Skip Link
 */
.header-skip-link {
    background: #004178; /* green or blue #35a748 */
    color: #fff;
    font-size: 1.07692307692308em;
    font-family:  var(--red-hat-text);
    letter-spacing: 1px;
    padding: 14px 1em 13px;
    position: absolute;
    right: 0;
    top: -999em;
    white-space: nowrap;
    z-index: 100;
}
.header-skip-link:focus, .header-skip-link:active {
    top: 0;
}
@media screen and (min-width: 60em) {
    .header-skip-link {
        padding-bottom: 16px;
        padding-top: 17px;
    }
}
@media screen and (min-width: 62em) {
    .header-skip-link {
        margin-right: -495px;
        right: 50%;
    }
}

/*
 * Header Bar Logo
 */ 
.headerbar-logo {
    color: #fff;
    display: block;
    float: left;
    height: 27px;
    margin: 10px 15px;
    position: relative;
    width: 60px;
}
.headerbar-logo-mask {
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
}
.headerbar-logo img {
    left: 0;
    position: absolute;
    top: -80px;
}
.svg-logo {
    fill: #fff !important;
    height: 100%;
    width: 100%;
}
.pcl-logo-seawitch {
    display: block;
}
.pcl-logo-cbn {
    display: none;
}

/* fall back solution for browsers that don't support svg */
.no-svg .headerbar-logo {
    background-image: url(/images/global/princess-logo_h.png);
    background-position: none;
    background-position: 0 -80px;
}
.no-svg .headerbar-logo img {
    display: none;
}
.headerbar-app {
    float: right;
    line-height: 1;
    padding: 5px 15px 0;
    text-align: right;
}
@media screen and (min-width: 60em) {
    /* for desktops, hide the icon in the headerbar (will show in navbar) */
    .pcl-logo-seawitch {
        display: none;
    }
    .pcl-logo-cbn {
        display: block;
    }
    .headerbar-logo {
        height: 20px;
        width: 170px;
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .headerbar-logo img {
        top: 0;
    }
    .show-headerbar-logo .headerbar-logo, .pinned .headerbar-logo {
        display: block;
    }
    /* for pinned headerbar, set the headerbar to be invisible so that we can fade it in with a transition - currently not in use 
    .pinned .headerbar-logo {
        opacity: 0;
    }
    .fadein-headerbar-logo .headerbar-logo {
        opacity: 1;
        transition: opacity .75s;
    }
    /* override pinned styles when show-headerbar-logo is in use 
    .show-headerbar-logo .headerbar-logo {
        opacity: 1;
        transition: none;
    }
    */
}
.onesource-app {
    background: url(/images/global/sprite_header-nav-footer.png) no-repeat -40px -310px transparent;
    height: 10px;
    margin-bottom: 2px;
    width: 105px;
}
.app-title {
    text-transform: uppercase;
    padding: 6px 0;
}
@media screen and (min-width: 30em) {
    .app-title {
        font-size: 1.384615384615385em;
    }
}
.onesource-app + .app-title {
    padding: 0;
}


/*
 * Header Bar Buttons
 */
.headerbar-menu {
    font-size: 0; /* setting the font-size to zero, to eliminate the spacing between the top level dropdown */
    list-style: none;
    padding-left: 0;
    margin: 0;
    text-align: right;
}
.headerbar-menu.affiliates-wrapper {
    display: none;
}
.headerbar-btn {
    font-size: .8125rem; /* reset the font-size to the default size of 13px to ensure all relative sizes match up */
}
.headerbar-btn .headerbar-btn-link, .headerbar-btn .headerbar-btn-text, .headerbar-btn .headerbar-touch-btn {
    font-family: var(--red-hat-text);
    letter-spacing: 1px;
}
.headerbar-btn-link, .headerbar-btn-text, .headerbar-touch-btn {
    color: #fff;
    display: block;
    font-family: var(--red-hat-text);
    line-height: 1.42857142857143;
    letter-spacing: 1px;
    padding-left: 10px;
    padding-right: 10px;
    text-decoration: none;
}
.headerbar-btn-link, .headerbar-btn-text {
    font-size: 1.07692307692308em;
    padding-bottom: 13px;
    padding-top: 14px;
    white-space: nowrap;
}
/* fixing 1px difference between button and link */
button.headerbar-btn-link {
    padding-top: 13px;
}
@media screen and (min-width: 60em) {
    .headerbar-btn-link, .headerbar-btn-text {
        padding-bottom: 16px;
        padding-top: 16px;
    }
    /* fixing 1px difference between button and link */
    button.headerbar-btn-link {
        padding-top: 15px;
    }
}
.headerbar-btn .headerbar-btn-icon {
    height: 1.142857142857143em;
    margin-top: -0.142857142857143em;
    width: 1.142857142857143em;
}
.headerbar-btn.book-cruise .headerbar-btn-icon {
    width: 40px;
    height: 40px;
    margin-top: -10px;
    margin-bottom: -10px;
}
@media screen and (min-width: 48em) {
    .headerbar-btn.book-cruise .headerbar-btn-icon {
        margin-top: -5px;
        margin-bottom: -5px;
    }
}
.headerbar-btn.email-offers .headerbar-btn-icon {
    height: 1.285714285714286em;
    width: 1.285714285714286em;
}

/*
.headerbar-btn.cruise-deals .svg-icon {
    height: 16px;
    margin-top: -2px;
}
.headerbar-btn.my-account .svg-icon {
    height: 16px;
    margin-top: -2px;
}
*/
.headerbar-btn.saved-cruises .headerbar-btn-icon {
    height: 1.285714285714286em;
    width: 1.285714285714286em;
}
.headerbar-btn.click-to-chat .headerbar-btn-icon {
    height: 1.714285714285714em;
    width: 1.714285714285714em;
}

.headerbar-btn.for-touch .headerbar-btn-icon {
    height: 1.714285714285714em;
    margin: -0.285714285714286em auto -0.214285714285714em;
    width: 1.714285714285714em;
}
.headerbar-btn.call .headerbar-btn-icon {
    height: 1.285714285714286em;
    margin: -1px auto 0;
    width: 1.285714285714286em;
}

.headerbar-touch-btn {
    padding-bottom: 5px;
    padding-top: 7px;
    text-align: center;
}
.headerbar-touch-btn:focus{
   background: #004178;
}
.headerbar-site-search-text {
    display: inline-block;
    vertical-align: middle;
}
.no-touch .headerbar-btn-link:hover,
.headerbar-btn-link:focus {
    background: #004178;
}
.headerbar-btn.need-help, .headerbar-btn.click-to-chat {
    display: none;
}
@media screen and (min-width: 48em) {
    .show-headerbar-need-help .headerbar-btn.need-help,
    .show-headerbar-chat .headerbar-btn.click-to-chat {
        display: inline-block;
    }
}

/*
.headerbar-chat-link.chat-is-online {
    background: #35a748;
}
.headerbar-chat-link.chat-is-online:hover {
    background: #2a8338;
}
*/

.hide-headerbar-find-cruises .headerbar-btn.find-cruises, 
.hide-headerbar-my-account .headerbar-btn.my-account,
.hide-headerbar-my-account .my-account-dropdown,
.hide-headerbar-email-offers .headerbar-btn.email-offers, 
.hide-headerbar-cruise-deals .headerbar-btn.cruise-deals, 
.hide-headerbar-saved-cruises .headerbar-btn.saved-cruises, 
.hide-headerbar-site-search .headerbar-btn.site-search, 
.hide-headerbar-call .headerbar-btn.call {
    display: none;
}
@media screen and (max-width: 47.9375em) {
    .headerbar-btn {
        display: none;
    }
    .headerbar-btn.for-touch {
        border: none;
        display: inline-block;
        overflow: hidden;
        padding: 0;
    }
    .headerbar-btn.chat {
        display: none;
    }
    .show-headerbar-chat .headerbar-btn.chat,
    .show-headerbar-call .headerbar-btn.call {
        display: inline-block;
    } 
    .headerbar-btn.menu {
        right: 0;
    }
    .no-menu-btn .headerbar-btn.menu {
        display: none;
    }
    .headerbar-touch-btn .headerbar-btn-icon, .menu-icon {
        display: block;
        margin: 0 auto 5px;
    }
    .headerbar-app {
        position: absolute;
        left:-webkit-calc(50% - 150px / 2);
        left:-moz-calc(50% - 150px / 2);
        left:calc(50% - 150px / 2);
    }
    .menu-icon {
        height: 15px;
        margin-bottom: 2px;
        position: relative;
        width: 15px;
    }
    .bar {
        background: #fff;
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        -webkit-transition: all 100ms linear 0s;
        -moz-transition: all 100ms linear 0s;
        -o-transition: all 100ms linear 0s;
        transition: all 100ms linear 0s;
        top: 50%;
        width: 15px;
    }
    .top-bar {
        margin-top: -4px;
    }
    .bottom-bar {
        margin-top: 3px;
    }
    .menu > .expanded {
        background: #004178;
    }
    .menu > .expanded .top-bar {
        margin-top: 0;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .menu > .expanded .bottom-bar {
        margin-top: 0;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
}
@media screen and (min-width: 48em) {
    .headerbar-btn {
        display: inline-block;
        vertical-align: middle;
    }
    .headerbar-btn.for-touch {
        display: none;
    }
    .headerbar-btn-icon {
        margin: 0 .5em 0 0;
    }
}
@media print {
    .headerbar {
        display: none !important;
        visibility: hidden;
    }
}


/* =============================================================================
   Header Site Search
   ========================================================================== */
.header-site-search {
    background: #004178;
    display: none;
    padding: 0 15px;
    width: 100%;
}
.header-site-search form {
    position: relative;
    margin: 0;
}
.header-site-search .header-site-search-field {
    display: block;
    margin: 0;
    padding: .76922307em 0;
    position: relative;
    width: 100%;
}
.header-site-search input[type="text"] {
    border: 1px solid #999;
    font-size: 1rem;
    line-height: 18px;
    margin: 3px 0;
    padding: 4px 30px 4px 4px ;
    width: 100%;
    transition: color 0.2s linear 0s, box-shadow 0.2s linear 0s;
    vertical-align: baseline;
}
.header-site-search .header-site-search-btn {
    background: none;
    border: none;
    color: #0054a0;
    display: block;
    height: 24px;
    margin: 0;
    padding: 5px;
    position: absolute;
    right: 2px;
    top: 15px;
    width: 23px;
}
.header-site-search-btn .header-site-search-icon {
    height: 16px;
    width: 16px;
}
@media screen and (max-width: 47.9375em) {
    .header-site-search-container {
        position: relative;
    }
    .header-site-search .close-header-site-search-btn {
        display: none;
    }
    .header-site-search.visible-in-menu {
        display: block;
        position: absolute;
        top: 45px;
        margin-top:-45px;
        transition: .75s top;
        z-index: 999;
    }
    .fixed + .header-site-search-container {
        position: relative;
    }
    .fixed + .header-site-search-container > .header-site-search.visible-in-menu {
        position: fixed;
        top: 45px;
        margin-top: 0;
    }
    .fixed + .header-site-search-container > .header-site-search.visible-in-menu.unpinned {
        top: -100px;
    }
}
@media screen and (min-width: 48em) {
    .header-site-search-container {
        height: 0;
        margin: 0 auto;
        position: relative;
        max-width: 990px;
        width: 100%;
    }
    .header-site-search {
        max-width: 600px;
        padding: 3px 30px 3px 10px;
        position: absolute;
        right: 0;
        top: -47px;
        z-index: 1000;
    }
    .fixed + .header-site-search-container > .header-site-search {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 1001;
    }
    .not-fixed + .header-site-search-container > .header-site-search {
        position: absolute;
        top: -45px;
    }
    .header-site-search.visible {
        display: block;
    }
    .header-site-search form {
        width: 100%;
    }
    .header-site-search .header-site-search-field {
        padding: 3px 0;
    }
    .header-site-search .header-site-search-btn {
        right: 5px;
        top: 8px;
    }
    .header-site-search .close-header-site-search-btn {
        background-position: -365px -15px;
        border: 1px solid #004178;
        border-radius: 50%;
        display: block;
        height: 22px;
        padding: 5px;
        position: absolute;
        margin: 0;
        right: 4px;
        top: 10px;
        width: 22px;
    }
    .header-site-search .close-header-site-search-btn > .svg-icon {
        fill: #004178;
        position: absolute;
        right: -2px;
        top: -2px;
    }
}
@media screen and (min-width: 60em) {
    .header-site-search {
        padding-bottom: 6px;
        padding-top: 6px;
        top: -57px;
    }
    .header-site-search .close-header-site-search-btn {
        top: 14px;
    }
}
@media screen and (min-width: 61.875em) {
    .pinned + .header-site-search-container > .header-site-search {
        margin-right: -495px;
        right: 50%;
    }
}

/* =============================================================================
   Translations (Language Dropdown)
   ========================================================================== */

/*
 * Language (Header Bar)
 */
.headerbar-btn.language .svg-icon.svg-icon-globe {
    width: 21px;
    height: 21px;
}
.language-link:hover,
.language-link:focus {
    text-decoration: underline;
}
.language-subhead {
    font-family:  var(--red-hat-text);
    font-size: 1.230769230769231em;
    letter-spacing: 1px;
    padding-bottom: 5px;
}
.headerbar .language-dropdown {
    background: #004178;
    box-shadow: 0 5px 5px 0 rgba(0,0,0,.5);
    color: #fff;
    left: 0;
    min-width: 14em;
    padding: 0 15px 5px;
    position: absolute;
    text-align: left;
    top: -999em;
    /* top: 100%; */
    z-index: 1000;
}
.headerbar .language {
    position: relative;
}
.headerbar .language.expanded > a,
.headerbar .language.expanded > button {
    background: #004178;
}
.language-col {
    padding-bottom: 8px;
    padding-top: 12px;
}
.language-link {
    display: block;
    color: #fff;
    padding: .4em;
    margin: 0 -5px;
    text-decoration: none;
}
.language-link.active {
    background: #fff;
    color: #004178;
    text-decoration: none;
}
.language.expanded > .language-dropdown {
    top: 100%;
}

/*
 * Lanugage (Nav Bar)
 */
.navbar-btn.language .navbar-btn-menu-link {
    position: relative;
    margin: 0;
    border: 0;
    background: transparent;
    font-family: var(--red-hat-text);
}
.navbar-btn.language .svg-icon-globe {
    margin: 0 auto .25em;
    display: block;
    height: 1.8em;
    width: 1.8em;
    margin-top: -0.093em;
}
.navbar-wrapper .language-dropdown {
    background: #004178;
    display: none;
}
.navbar-wrapper .language-link {
    margin: 0;
}
.navbar-wrapper .language-grid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr auto;
    grid-template-columns: 1fr auto;
    padding: 10px 15px;
}
.navbar-wrapper .language-grid > .language-dropdown-anchors {
    -ms-grid-column: 1;
    grid-column: 1;
}
.navbar-wrapper .language-grid > .language-dropdown-arrows {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row-align: start;
    align-self: start;
    margin: 4px 0 0 15px;
}
.navbar-wrapper .language-dropdown a {
    padding-left: 10px;
    padding-right: 10px;
}
.navbar-wrapper .language.expanded a:after {
    content: '';
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #004178;
    display: inline-block;
    height: 0;
    position: absolute;
    width: 0;
    left: 42%;
    bottom: -1px;
}
button.language-arrow {
    background: transparent;
    border: none;
    padding: 0;
}
button.language-arrow:after {
    content: '';
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    display: inline-block;
    height: 0;
    width: 0;
    top: 10px;
}
@media screen and (min-width: 48em) {
    .navbar .language-dropdown {
        display: none!important;
    }
}


/* =============================================================================
   Nav Bar
   ========================================================================== */

/*
 * Navbar container and top level buttons
 */
.navbar {
    background: #fff;
    border-bottom: 1px solid #ccc;
}
.navbar-wrapper {
    margin: 0 auto;
    position: relative;
    max-width: 100%;
}
@media screen and (max-width: 47.9375em) {
    .navbar {
        border-bottom: 5px solid #0da2d4;
        height: 0;
        overflow: hidden;
        margin-bottom: 0!important;
    }
    .navbar-wrapper {
        padding-top: 54px;
    }
}
.navbar-wrapper > .container {
    position: relative;
}
.navbar-menu {
    font-size: 0; /* setting the font-size to zero, to eliminate the spacing between the top level dropdown */
    margin: 0;
}
.navbar-dropdown {
    font-size: .8125rem; /* reset the font-size to the default size of 13px to ensure all relative sizes match up */
}
/* top level buttons in navbar */
@media screen and (min-width: 48em) and (max-width: 59.9375em) {
    .navbar-menu {
        text-align: center;
    }
    .navbar-dropdown {
        display: inline-block;
    }
}
@media screen and (min-width: 60em) {
    .navbar-menu {
        text-align: center;
    }
    .navbar-dropdown {
        display: inline-block;
    }
    .navbar-dropdown + .navbar-dropdown {
        margin-left: 2em;
    }
    .hide-navbar-logo .navbar .navbar-menu {
        text-align: center;
    }
}

/*
 * Navbar dropdown layout
 */ 
.navbar-dropdown-menu {
    background: #e5f3fd;
    height: 0;
    overflow: hidden;
    position: relative;
    text-align: left;
    width: 100%;
}
.no-js .navbar-dropdown:hover > .navbar-dropdown-menu,
.no-js .navbar-dropdown:focus > .navbar-dropdown-menu,
.navbar-dropdown.expanded > .navbar-dropdown-menu {
    height: auto;
}
.navbar-row {
    position: relative;
}
.navbar-col {
    padding-left: 4.04040404040404%;
    padding-right: 4.04040404040404%;
}
.navbar-row-divider {
    margin-bottom: 15px;
    margin-top: 15px;
    position: relative;
    border-top: 1px solid #ccc;
}
.navbar-btn-row {
    background: #f7fbfe;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    padding: 15px 0;
    text-align: center;
}
.navbar-subhead {
    font-family:  var(--red-hat-text);
    font-size: 1.230769230769231em;
    letter-spacing: 1px;
    margin-top: 1em;
}
.navbar-subhead:first-child {
    margin-top: 0;
}   
.navbar-subhead > .phone-nu {
    white-space: nowrap;
}
.navbar-close {
    border: 0;
    background: transparent;
    position: absolute;
    top: 15px;
    right: 15px;
}
@media screen and (max-width: 47.9375em) {
    /* apply inset shadows in mobile view */
    .no-js .navbar-dropdown:hover > .navbar-dropdown-menu,
    .no-js .navbar-dropdown:focus > .navbar-dropdown-menu,
    .navbar-dropdown.expanded > .navbar-dropdown-menu {
        box-shadow: inset 0 10px 10px -10px rgba(0,0,0,.5);
    }
    .no-js .navbar-dropdown:hover .navbar-dropdown-menu > div:last-child,
    .no-js .navbar-dropdown:focus .navbar-dropdown-menu > div:last-child,
    .navbar-dropdown.expanded .navbar-dropdown-menu > div:last-child {
        box-shadow: inset 0 -10px 10px -10px rgba(0,0,0,.5);
    }
    /* apply same padding sizes from left/right to the top/bottom for mobile view */
    .navbar-col {
        padding-bottom: 4.04040404040404%;
        padding-top: 4.04040404040404%;
    }
    /* col-divider will be horizontal and full-width in mobile view */
    .navbar-col-divider {
        position: relative;
        border-top: 1px solid #ccc;
    }
    /* hide responsive images in mobile view */
    .navbar-dropdown-menu  .navbar-col > .img-responsive-wrapper {
        display: none;
    }
    /* hide phone number for mobile */
    .navbar-dropdown-phone-nu {
        display: none;
    }
}
@media screen and (min-width: 48em) {
    .navbar-row {
        padding-bottom: 30px;
        padding-top: 30px;
    }
    .navbar-dropdown-menu {
        left: 0;
        position: absolute;
        top: -999em;
        z-index: 999;
    }
    .no-js .navbar-dropdown:hover > .navbar-dropdown-menu,
    .no-js .navbar-dropdown:focus > .navbar-dropdown-menu,
    .navbar-dropdown.expanded > .navbar-dropdown-menu {
        box-shadow: inset 0px 11px 20px -7px #5454543b, inset 0px -11px 20px -7px #5454543b;
        top: 101%;
    }
    .navbar-col {
        float: left;
        width: 33.33333333333333%;
    }
    .navbar-col-divider {
        bottom: 20px;
        position: absolute;
        top: 20px;
        border-left: 1px solid #ccc;
    }
    .navbar-col-divider.left {
        left: 33.33333333333333%;
    }
    .navbar-col-divider.right {
        right: 33.33333333333333%;
    }
}

/* 
 * Link styles in navbar
 */
.navbar-link, .navbar-btn-link, .accolade,
.navbar-link:focus, .navbar-btn-link:focus, .accolade:focus  {
    color: #0054A0;
}
.navbar-link {
    text-align: left;
    text-decoration: none;
}
.navbar-dropdown > .navbar-link, .navbar-dropdown-phone-nu > .loc-phone-nu {
    background: #fff;
    border-bottom: 1px solid #ccc;
    display: block;
    font-family: var(--red-hat-text);
    font-size: 1.230769230769231em;
    letter-spacing: 1px;
    padding: .5em 10px;
}
/* need to reduce the padding slightly to make up the difference on the 960 width container for non responsive pages */
.no-rwd .navbar-dropdown > .navbar-link {
    padding-left: 13px;
    padding-right: 13px;
}
.navbar-dropdown-arrow {
    float: right;
    height: 18px;
    top: 2px;
    position: relative;
    transform: rotate(90deg);
}
.expanded .navbar-dropdown-arrow {
    transform: rotate(-90deg);
}
.navbar-dropdown-toggle:hover, .navbar-dropdown-toggle:focus {
    text-decoration: none;
}
.no-js .navbar-dropdown:hover > .navbar-link,
.no-touch .navbar-dropdown > .navbar-link:hover,
.navbar-dropdown > .navbar-link:focus,
.navbar-dropdown.expanded > .navbar-link {
    background: #e5f3fd;
}
@media screen and (max-width: 47.9375em) {
    .navbar-dropdown:first-child > .navbar-link  {
        border-top: 1px solid #ccc;
    }
    .navbar-dropdown .navbar-dropdown-toggle {
        position: relative;
    }
    .navbar-dropdown .navbar-dropdown-toggle:after {
        content: '';
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #0054A0;
        display: inline-block;
        height: 0;
        position: absolute;
        width: 0;
        right: 15px;
        top: 15px;
    }
    .navbar-dropdown.expanded .navbar-dropdown-toggle:after {
        border-top: 0;
        border-bottom: 10px solid #0054A0;
    }
}
@media screen and (min-width: 48em) {
    .navbar-dropdown-toggle > .svg-icon {
        display: none;
    }
    .navbar-dropdown > .navbar-link, .navbar-dropdown-phone-nu > .loc-phone-nu {
        border-bottom: 2px solid #fff;
        padding-bottom: 19px;
        padding-top: 19px;
    }
    .no-js .navbar-dropdown:hover > .navbar-link,
    .no-touch .navbar-dropdown > .navbar-link:hover,
    .navbar-dropdown > .navbar-link:focus,
    .navbar-dropdown.expanded > .navbar-link {
        border-bottom: 2px solid #0da2d4;
    }
}
.navbar-dropdown-menu .navbar-link {
    display: block;
    line-height: 1.384615384615385;
    padding: .5em 0;
}
.navbar-dropdown-menu .navbar-link:hover {
    text-decoration: underline;
}
.navbar-btn-row .navbar-btn-link {
    padding: 10px 15px;
}
.navbar-btn-row .navbar-btn-link, .navbar-btn-row .button, .chat-btn {
    display: inline-block;
    text-decoration: none;
}
.navbar-btn-row .navbar-btn-link:hover,
.navbar-btn-row .navbar-btn-link:focus {
    text-decoration: underline;
}
.navbar-btn-row .cruise-with-me {
    white-space: nowrap;
    text-align: center;
}
.navbar-btn-row .chat-btn {
    color: #fff;
    margin-left: 1em;
}
.navbar-btn .navbar-btn-icon {
    height: 1.714285714285714em;
    width: 1.714285714285714em;
}
.navbar-btn.email-offers .navbar-btn-icon {
    height: 2em;
    margin-top: -0.285714285714286em;
    width: 2em;
}

/* Accolades in navbar-btn-row */
.accolade > .navbar-svg-icon {
    position: absolute;
    height: 48px;
    margin-top: -24px;
    right: 0;
    top: 50%;
}
.accolade > .navbar-svg-icon:first-of-type {
    left: 0;
    transform: scaleX(-1);
}

@media screen and (max-width: 42.4375em) {
    .navbar-btn-row .navbar-btn-link, .navbar-btn-row .button {
        display: block;
    }
    .chat-btn {
            display: inline-block;
        }
    .navbar-btn-row .button {
        max-width: 290px;
        margin-left: auto;
        margin-right: auto;
    }
}
@media screen and (max-width: 51.9375em) {
    .navbar-btn-row .cruise-with-me {
        display: block;
    }   
}

/*
 * Call center hours
 */
.call-center-hours > strong {
    display: inline-block;
    padding-top: .5em;
}

/* =============================================================================
   Buttons in mobile view of navbar
   ========================================================================== */
/* 
 * Find a Cruise & Recently Viewed buttons at the bottom of the mobile view 
 */
.navbar-search-cruises {
    padding: 15px;
    text-align: center;
}
@media screen and (min-width: 48em) {
    .navbar-search-cruises {
        display: none;
        visibility: hidden;
    }
}
.navbar-search-cruises > a {
    display: inline-block;
    max-width: 290px;
    width: 100%;
}
.navbar-search-cruises > a + a {
    margin-top: 10px;
    padding: .3076em 1.1538em;
}

/*
 * Button row at the top of the mobile menu
 */
.navbar-btn-menu {
    background: #f1f1f1;
    border-top: 1px solid #ccc;
    color: #0054A0;
    font-size: 0; /* setting the font-size to zero, to eliminate the spacing between the top level dropdown */
    list-style: none;
    padding-left: 0;
    margin: 0;
    text-align: center;
    width: 100%;
}
@media screen and (min-width: 48em) {
    .navbar-btn-menu {
        display: none;
        visibility: hidden;
    }
}
.navbar-btn {
    display: inline-block;
    font-size: .8125rem; /* reset the font-size to the default size of 13px to ensure all relative sizes match up */
}
.navbar-btn + .navbar-btn {
    margin-left: 0;
}
.navbar-btn.tap-to-call {
    display: none;
}
@media screen and (min-width: 23.4375em) {
    .navbar-btn + .navbar-btn {
        margin-left: 3.5%;
    }
}
.navbar-btn-menu-link {
    color: #0054A0;
    display: block;
    font-family: var(--red-hat-text);
    letter-spacing: 1px;
    line-height: 1.307692307692308;
    padding: 10px;
    text-decoration: none;
}
.navbar-btn-menu-link:hover, .navbar-btn-menu-link:focus,
.navbar-btn.language .navbar-btn-menu-link:hover, .navbar-btn.language .navbar-btn-menu-link:focus {
    background: #bfbfbf;
}
.navbar-btn-menu-link .navbar-btn-icon {
    display: block;
    margin: 0 auto .25em;
    vertical-align: middle;
}



/* =============================================================================
   My Account dropdown in headerbar & mobile navbar
   ========================================================================== */
.my-account {
    position: relative;
}
.my-account-welcome, .my-account-logout-btn, .my-account-link {
    padding: .4em 15px;
}
.my-account-divider {
    padding: .4em 0;
}
.my-account-logout-btn {
    background: none;
    border: none;
    color: #0054A0;
    margin: 0;
}
.my-account-logout-btn, .my-account-link {
    display: inline-block;
}
.my-account-link {
    text-decoration: none;
}
.my-account-link:hover, .my-account-link:focus {
    text-decoration: underline;
}
.my-account-reg-btn, .my-account-logout-btn, .my-account-login-btn {
    text-decoration: underline;
}
.my-account-reg-btn:hover, .my-account-logout-btn:hover, .my-account-login-btn:hover,
.my-account-reg-btn:focus, .my-account-logout-btn:focus, .my-account-login-btn:focus {
    text-decoration: none;
}
@media screen and (max-width: 47.9375em) {
    .headerbar .my-account-dropdown {
        display: none;
    }
    .navbar .my-account-dropdown {
        padding: .5em 0;
        width: 100%;
    }
    .navbar .my-account-welcome {
        float: left;
    }
    .navbar .my-account-divider  {
        float: right;
    }
}
@media screen and (min-width: 48em) {
    .navbar .my-account-dropdown {
        display: none;
    }
    .headerbar .my-account {
        position: relative;
    }
    .no-js .headerbar .my-account:hover > a,
    .no-js .headerbar .my-account:focus > a,
    .headerbar .my-account.expanded > a {
        background: #004178;
    }
    .headerbar .my-account-dropdown {
        background: #004178;
        box-shadow: 0 5px 5px 0 rgba(0,0,0,.5);
        color: #fff;
        left: 0;
        min-width: 14em;
        padding: 0 15px 5px;
        position: absolute;
        text-align: left;
        top: -999em;
        z-index: 1000;
    }
    .hide-headerbar-saved-cruises.hide-headerbar-site-search .my-account-dropdown {
        left: auto;
        right: 0;
    }

    .no-js .my-account:hover > .my-account-dropdown,
    .no-js .my-account:focus > .my-account-dropdown,
    .my-account.expanded > .my-account-dropdown {
        top: 100%;
    }
    .headerbar .my-account-welcome {
        padding-bottom: .25em;
    }
    .headerbar .my-account-welcome, .headerbar .my-account-logout-btn, .headerbar .my-account-reg-btn, .headerbar .my-account-link  {
        color: #fff;
    }
    .headerbar .my-account-welcome, .headerbar .my-account-link  {
        padding-left: 0;
        padding-right: 0;
    }
    .headerbar .my-account-logout-btn {
        padding-left: 5px;
        padding-right: 5px;
    }
    .headerbar .user-logged-in, .headerbar .user-logged-out {
        padding-top: 10px;
        padding-bottom: 10px;
        white-space: nowrap;
    }
    .headerbar .my-account-col {
        border-top: 1px solid #b2c0cb;
        padding-bottom: 8px;
        padding-top: 12px;
    }
    .headerbar .my-account-col + .user-logged-out {
        padding-top: 15px;
    }

    .headerbar .my-account-col > .my-account-link {
        display: block;
    }
    .my-account-subhead {
        font-family:  var(--red-hat-text);
        font-size: 1.230769230769231em;
        letter-spacing: 1px;
        margin-top: 10px;
    }
    .my-account-subhead:first-child {
        margin-top: 0;
    }
}

/* =============================================================================
   Saved Cruises Icon
   ========================================================================== */
.headerbar-btn.saved-cruises > .headerbar-btn-link,
.navbar-btn.saved-cruises > .navbar-btn-menu-link {
    position: relative;
}
.saved-cruise-count {
    background: #e7131a;
    border-radius: 50%;
    color: #fff;
    display: none;
    font-size: .71428571428571em;
    line-height: 1;
    padding: 4px 2px 4px 0;
    position: absolute;
    text-align: center;
    width: 20px;
}
.navbar-btn-menu-link > .saved-cruise-count {
    left: 10px;
    top: 8px;
}
.headerbar-btn-link > .saved-cruise-count {
    left: 1px;
    top: 5px;
}


/* ==========================================================================
   ==========================================================================
   ========================================================================== */


/* =============================================================================
   Princess Cruises Footer
   ========================================================================== */
#princess-footer {
    background:#f7f7f7;
    border-bottom: solid 16px #0054A0;
    clear: both;
    margin-top: 30px;
    padding-bottom: 25px;
}
#footer, #princess-footer #footer{
    margin: 0 auto;
    overflow: hidden;
    text-align: left;
    width: 960px;
}
#footer > .inner-wrapper {
    background: url(/images/global/footer-horizontal-divider.png) no-repeat center bottom;
    margin-bottom: 10px;
    overflow: hidden;
    padding: 10px 0;
}
.footer-links {
    float: left;
    max-width: 840px;
}
.footerCol {
    border-left: 1px solid #dedede;
    float: left;
    padding: 0 14px;
    min-width: 105px;
    max-width: 200px;
}
.footerCol.last {
    padding-right: 0;
}
.footerCol:first-child {
    border-left: 0;
    padding-left: 0;
}
.footerCol.span2 {
    max-width: 400px;
}
.get-brochure {
    width: 129px;
}
#footer a {
    color: #000;
    text-decoration: none; 
}
#footer a:hover {
    text-decoration: underline; 
}
.footerCol h2 {
    background: transparent; /*override other apps*/
    border: none; /*override other apps*/
    clear: both;
    color: #000; /*override other apps*/
    font-family: var(--red-hat-text);
    font-size: 1.071428571428571em; /* 15px */
    font-weight:400;
    letter-spacing: 1px;
    line-height: 1.5833em;
    margin: 0;
    padding: 2em 0 0;
    text-transform: none; /*override other apps*/
}
.lt-ie9 .footerCol h2 {
    letter-spacing: 0px;
}
.footerCol > h2:first-child {
    padding-top: 0;
}
.footerCol ul {
    list-style: disc;
    margin: .4em 0;
    margin-top: 2px;
    padding: 0 0 0 15px; /*override other apps*/
}
.footerCol > ul, .cruise-deals > ul, .princess-rewards > ul {
    list-style: none;
    padding: 0;
}
.footerCol > ul.footer-news-links {
    list-style: disc;
    padding-left: 15px;
}
.footerCol li {
    color: #000; /*override other apps*/
    font-size: 0.857142857142857em; /*12px*/
    line-height: 1.3333;
    margin: 0; /*override other apps*/
}
.footerCol li li {
    font-size: 1em;
}
.footerCol .black-ada-12 {
    margin-left: 0;
}
.copyright {
    color: #000;
    font-size: 0.857142857142857em;
    padding-top: 4px;
    width: 100%;
}
.uk-compliant-logos > .svg-icon {
    position: relative;
    vertical-align: baseline
}
.uk-compliant-logos > .icon-abta {
    bottom: -4px;
    height: 22px;
    width: 68px;
}
.uk-compliant-logos > .icon-clia {
    bottom: -3px;
    height: 26px;
    width: 45px;    
}
.uk-compliant-logos > .icon-atol {
    bottom: -4px;
    height: 24px;
    width: 24px;
}
#footer > .copyright a {
    color: #000;
    text-decoration: underline;
}
#footer > .copyright a:hover, #footer > .copyright a:focus {
    text-decoration: none;
}
.travel-agent-logo {
    margin: 10px 0 0;
    float: left;
}
.mouseflow-number {
    background: #f6efc1;
    display: none;
    float: right;
    font-size: 1em;
    margin: 10px 0 0;
    padding: 5px 15px;
}
#footer-contact {
    background:#333;
    color:#FFF;
    font-size: 1.384615384615385em;
    margin-bottom:20px;
    padding:15px 0;
    text-align:center;
}
#footer-contact a {
    color:#FFF;
    padding: 0 .75em;
    border-left: 1px solid #fff;
}


/*
 * Social and brochure links
 */
#footer .social-links {
    padding-top: 5px;
    padding-bottom: 5px;
}
#footer .blog-link {
    padding: 20px 0 5px;
}
@media screen and (min-width: 960px) {
    #footer .visa-link {
        display: none;
    }
}
#footer .visa {
    border-radius: 2px;
    box-shadow: 3px 3px 3px rgba(0,0,0,.3);
    display: inline-block;
    background: url(/images/global/visa-cc.jpg) no-repeat transparent;
    height: 63px;
    margin-bottom: 3px;
    overflow: hidden;
    text-indent: -999em;
    white-space: nowrap;
    width: 100px;
}
.fvis.social li{
    position: relative;
    display: inline-block;
}
/*flipping the way the social media icons are viewed and hovered over*/
.follow-princess-icon {
   background-color:transparent;
    display: inline-block;
    position: relative;
    margin-top: 5px;
    padding: 2px 3px 3px;
}
.follow-princess-icon > .svg-icon-facebook {
    color: #33569a;
    transition: all .25s;
}
.follow-princess-icon > .svg-icon-google-plus {
    color: #db4437;
    transition: all .25s;
}
.follow-princess-icon > .svg-icon-twitter {
    color: #1da1f2;
    transition: all .25s;
}
.follow-princess-icon > .svg-icon-youtube {
    color: #ff0000;
    transition: all .25s;
}
.follow-princess-icon > .svg-icon-pinterest {
    color: #bd081c;
    transition: all .25s;
}
.follow-princess-icon > .svg-icon-instagram {
    color: #e22574;
    transition: all .25s;
}
.follow-princess-icon:hover, .follow-princess-icon:focus {
    background-color: #666;
}
.follow-princess-icon:hover > .svg-icon-facebook, .follow-princess-icon:focus > .svg-icon-facebook {
    color: #fff;
}
.follow-princess-icon:hover > .svg-icon-google-plus, .follow-princess-icon:focus > .svg-icon-google-plus {
    color: #fff;
}
.follow-princess-icon:hover > .svg-icon-twitter, .follow-princess-icon:focus > .svg-icon-twitter {
    color: #fff;
}
.follow-princess-icon:hover > .svg-icon-youtube, .follow-princess-icon:focus > .svg-icon-youtube {
    color: #fff;
}
.follow-princess-icon:hover > .svg-icon-pinterest, .follow-princess-icon:focus > .svg-icon-pinterest {
    color: #fff;
}
.follow-princess-icon:hover > .svg-icon-instagram, .follow-princess-icon:focus > .svg-icon-instagram {
    color: #fff;
}
#footer .brochures {
    display: inline-block;
    background: url(/images/global/footer-brochure-icon.png) no-repeat transparent;
    height: 89px;
    overflow: hidden;
    text-indent: -999em;
    white-space: nowrap;
    width: 89px;
}

/* =============================================================================
   Popup and Alert Windows
   ========================================================================== */

/*
 * Inactivity Warning Popup   --   NEED TO LOOK AT TO MAKE SURE ITS OKAY
 */
#inactivity-warning {
    position: absolute;
    top: 100px;
    right: 0;
    width: 290px;
    height: 175px;
    border: 1px solid #8596b1;
    padding: 5px 20px 0 10px;
    margin-bottom: 5px;
    background: url(/images/global/register_block_bg.gif) repeat-x left 170px #f7f7f7;
    text-align: left;
    -webkit-box-shadow: 2px 7px 8px #666;
    -moz-box-shadow: 2px 7px 8px #666;
    box-shadow: 2px 7px 8px #666;
    z-index:5011
}
#inactivity-warning h3 {
    font-size: 1.214285714285714em; /*17px*/
    font-weight: 200;
    color: #000;
    border-bottom: 1px solid #CCC;
    padding-bottom: 2px;
    margin:4px 15px 12px 0;
}
#inactivity-warning .error {
    color:#DB0000;
    font-size: 1em; /* 14px */
    padding:2px 10px 18px 0
}
a.global-button {
    background:#224d91 url(/images/global/sign_in_btn_bg.gif) no-repeat 2px 3px;
    padding:2px 7px 2px 16px;
    text-transform:uppercase;
    border-radius:8px;
    color:#fff;
    text-decoration:none
}
a.global-button:hover { 
    text-decoration:none
}
#white-overlay {
    background-color:#fff;
    height:100%;
    left:0;
    position:fixed;
    top:0;
    width:100%;
    z-index:5010;
    opacity:0.98;
    filter: alpha(opacity=98);
    zoom: 1;
    overflow:hidden;
}
.close-window {
    clear: both;
    padding: 25px 0 10px;
    text-align: center;
}

/* Magnific Popup Signin / Special Offers Lightboxes */
.special-offers .mfp-content, .special-offers .mfp-close-wrapper {
    max-width: 640px;
}
.special-offers .mfp-content {
    min-height: 488px;
}
.special-offers .mfp-close-wrapper {
    margin: 0 auto;
}
@media screen and (min-width: 680px) and (max-width: 990) {
    .special-offers .mfp-close-inner-wrapper {
        margin-left: 0;
        margin-right: 0;
    }
}
body.mfp-entry-point {
    background: #fff;
}

/* iframe lightbox scaling for entry-points (ie sign in) */

.mfp-entry-point.mfp-entry-point-login .mfp-iframe-scaler iframe {
    border-bottom: none;
}
@media screen and (max-width: 29.8125em) {
    .mfp-entry-point .mfp-iframe-scaler {
        padding-top: 809px;
    }
}

@media screen and (min-width: 29.875em) and (max-width: 41.8125em) {
    .mfp-entry-point .mfp-iframe-scaler {
        padding-top: 762px;
    }
}
@media screen and (min-width: 41.875em) {
    .mfp-entry-point .mfp-iframe-holder .mfp-content,
    .mfp-entry-point .mfp-close-inner-wrapper {
        max-width: 640px;
        margin: 0 auto;
    }
    .mfp-entry-point .mfp-iframe-scaler {
        padding-top: 505px;
    }
}

/* CVP Fly-In - min height to match desktop  */
@media screen and (min-width: 768px) { 
    .cvp-leads .mfp-content {
        min-height: 969px;
  }	    
}

/* modal scaling and icon for inactivity notice / logout notice */
.mfp-guestauth-notice .mfp-content, .mfp-guestauth-notice .mfp-close-wrapper {
    max-width: 480px;
}
.mfp-guestauth-notice .mfp-close-wrapper {
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
}
.mfp-guestauth-notice .svg-icon-warning {
    height: 1.15em;
    width: 1.25em;
    fill: #fcb215;
    vertical-align: text-bottom;
}



/*
 * Sign-in Popup
 */
#sign-in-box {
    position: absolute;
    top: 42px;
    right: 0;
    background: #f7f7f7;
    width: 290px;
    height: 175px;
    text-align: left;
    padding: 5px 20px 0 10px;
    margin-bottom: 5px;
    border: 1px solid #8596b1;
    z-index: 300;
    -webkit-box-shadow: 2px 7px 8px #666; 
    -moz-box-shadow: 2px 7px 8px #666;
    box-shadow: 2px 7px 8px #666;
    display: none;
}
#sign-in-box h3 {
    font-size: 1.214285714285714em; /* 17px */
    line-height: .9411em;
    color: #000;
    border-bottom: 1px solid #CCC;
    padding: 0 0 2px 18px;
    margin: 4px 15px 12px 0;
    font-weight: 200;
    background: url(/images/global/sprite_header-nav-footer.png) no-repeat -135px -165px;
}
#sign-in-box .close-box {
    position: absolute;
    top: 4px;
    right: 9px;
    height: 13px;
    width: 13px;
    padding: 4px;
    text-align: center;
    cursor:pointer;
}
#sign-in-box .close-box img {
    background: url(/images/global/sprite_header-nav-footer.png) no-repeat -135px -190px;
}
#register-block {
    position:absolute;
    left:0;
    bottom:0;
    padding: 7px 41px;
    margin: 12px 0 0 0;
    width: 239px;
    background: url(/images/global/sprite_header-nav-footer.png) repeat-x 0 -125px #014c8f;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#3470a5), color-stop(100%,#014c8f));
    background: -webkit-linear-gradient(top, #3470a5 0%, #014c8f 100%);
    background: -moz-linear-gradient(top, #3470a5 0%, #014c8f 100%);
    background: -ms-linear-gradient(top, #3470a5 0%, #014c8f 100%);
    background: -o-linear-gradient(top, #3470a5 0%, #014c8f 100%);
    color: #FFF;
    text-align: center;
}
#register-block a {
    color: #FFF;
    text-transform: uppercase;
    font-size: 1em;
    padding-left: 20px;
    text-decoration:none
}
#register-block a:hover {
    text-decoration:none
}
#loading-symbol {
    position: absolute;
    top: 75px;
    left: 135px;
    width: 50px;
    display: none;
}
#iframe-page {
    height:115px !important;
    width:300px;
}

/*
 * Privacy Cookie Notice
 */
#privacy-notice {
    background: #fff;
    bottom: -125px; 
    border: 1px solid #b2b2b2;
    -moz-box-shadow: 5px 5px 10px #333333;
    -webkit-box-shadow: 5px 5px 10px #333333;
    box-shadow: 5px 5px 10px #333333;
    padding: 20px;
    position: fixed;
    right: 15px;
    width: 300px;
    z-index: 99999;
}
#close-privacy-notice {
    position: absolute;
    right: -15px;
    top: -23px;
    border: none;
    background: transparent;
}
#close-privacy-notice > svg {
    background: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

/* Koi Bar */
#koi-alert {
    background:#3B3B3B;
    clear:both;
    padding:10px 0;
    position:relative;
    width:100%;
}
#koi-alert > div {
    width:100%;
    margin:0 auto;
    padding:0 10px;
    text-align:center;
}
#koi-alert a {
    color:#999999;
    text-decoration:none;
}
@media screen and (min-width:640px) {
    /* Koi Bar */
    #koi-alert > div {
        text-align:right;
    }
}

@media screen and (min-width:768px) {
    /* Koi Bar */
    #koi-alert > div {
        padding:0 40px 0 0;
    }
}

@media screen and (min-width:960px) {
    /* Koi Bar */
    #koi-alert > div {
        width:960px;
    }
}


/* =============================================================================
   Print view
   ========================================================================== */
@media print {
    .header-site-search-container, .navbar-btn-menu, .navbar-menu, .navbar-search-cruises, #princess-footer, #navbar {
        display: none;
        visibility: hidden;
    }
}



