/*
 * Legacy Lightbox Styles
 */
.lightbox { 
    background: #fff; 
    border-top: 10px solid #0054A0;
    border-bottom: 10px solid #8cabc7; 
    display: none; 
    width: clamp(250px, 100vw, 930px);
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 0;
    position: absolute; 
    text-align: left;
    top: 121px; 
    visibility:visible; 
    z-index: 5004; 
}
.lightbox.onesource {
    border-top: 10px solid #c89618;
    border-bottom: 10px solid #ebd59d;
}
.lightbox.no-min-width {
    min-width: 0;
}
.lightbox.full-width {
    max-width: none;
    width: 90%;
}
#lightbox-overlay {
    background-color: black;
    height: 100%;
    left: 0;    
    opacity: 0.8;
    filter: alpha(opacity=80);
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5003;
    zoom: 1; 
}
* html #lightbox-overlay {
    height: 1000px;
    left: 0; 
    position: absolute; 
    top: 0; 
}
.lightbox-close-window {
    clear: both;
    line-height: 23px;
    padding: 10px 0 10px;
    position: relative;
    text-align: center;
    z-index: 400;
}    
.lightbox-close-window > .close-link {
    background: #666;
    border-radius: 0;
    color: #fff;
    display: inline-block;
    font-weight: 400;
    padding: 0.55555555555556em 1.66666666666667em;
    margin: 0;
    text-decoration: none;
    font-family:  var(--red-hat-text);
    font-size: 1.428571428571429em;
    letter-spacing: 1px;
}
.lightbox-close-window .close-link:hover,
.lightbox-close-window .close-link:focus {
    background: #333;
}
.lightbox-close-window-top {
    cursor: pointer;
    height: 50px;
    position: absolute; 
    object-fit: cover;
    width: 102px;
    overflow: hidden;
    right: 0;
    text-align: right;
    top: -28px;
}
/* force the panorama lightbox to never scroll */
.lightbox.panorama iframe {
    overflow: hidden !important;
}
/* set lightbox width to match video player*/
.lightbox.shorex-video #popupContent {
    width: 640px;
    min-height: 380px;
}
/*change the background of the lightbox to match signin page*/
.lightbox.special-offers, .lightbox.special-offers .lightbox-close-window {
    background-color: #eee;
}
/*center lightboxes that open an image file*/
.lightbox > img {
    margin: 25px 30px 10px;
}

/*
 * Lightbox content containers
 */
#popupContainer {
    background: #fff;
    margin: 0;
    overflow: auto;
    padding: 0;
    position: relative;
}
#popupContainer.light-blue-bg {
    background: #e5f3fd;
}
#PopupHeader {
    margin: 0;
    position: relative;
    text-align: left;
}
#popupTitleArea {
    background: #e5e5e5;
    overflow: hidden;
    padding: 15px;
    position: relative;
    width: auto;
}
#popupTitleArea.seawitch-icon {
    background: url(/images/global/logo_seawitch-icon.png) no-repeat 15px center #e5e5e5;
    padding-left: 105px;
}
#popupTitleArea h2 {
    margin: 0 5% 0 0;
}
#popupTitleArea p {
    margin: 0;
}
#popupContent{ 
    margin: 25px 30px 10px;
    position: relative;
}


/*
 * Lightbox using Magnific Popup Plugin
 */
.mfp-bg {
    opacity: 0;
    filter: alpha(opacity=60); 
    -webkit-transition: opacity 0.5s ease-out;
    -moz-transition: opacity 0.5s ease-out;
    transition: opacity 0.5s ease-out;
}
.mfp-bg.mfp-ready {
    opacity: 0.6;
}
.mfp-bg.mfp-removing {
    opacity: 0;
}
.mfp-container {
    padding: 30px 15px;
}
.mfp-content {
    opacity: 0;
    max-width: 960px;
    -webkit-transition: opacity 0.5s ease-out;
    -moz-transition: opacity 0.5s ease-out;
    transition: opacity 0.5s ease-out;
}
.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 960px;
}
.mfp-ready .mfp-content {
    opacity: 1;
}
.mfp-removing .mfp-content {
    opacity: 0;
}
.mfp-close-wrapper {
    height: 0; /* removed 50px height to allow items that scroll under mfp-close-wrapper to be clickable */
    left: 0;
    position: fixed;
    right: 0;
    top: 5px;
    width: 100%;
    z-index:1046;
}
.mfp-close-inner-wrapper {
    margin: 0 auto;
    position: relative;
    max-width: 960px;
}
/* close icon (no text) - legacy backup */
.mfp-content .mfp-close {
    background: url("/images/global/svg-library/close.svg") no-repeat;
    height: 30px;
    width: 30px;
    opacity: 1;
    position: absolute;
    margin: 0;
    top: 7px;
    right: -10px;
    text-indent: 999em;
    background-color: #fff;
    border-radius: 50%;
}
/* close icon (no text) - ada version */
.mfp-content .mfp-close.icon-no-text {
    background-clip: content-box;
    border-radius: 50%;
    opacity: 1;
    position: absolute;
    margin: 0;
    top: 12px;
    right: -2px;
    text-indent: 0;
    padding: 0;
    text-align: center;
}
.mfp-content .mfp-close.icon-no-text > svg {
    color: #000;
    background: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    pointer-events: none;
}
.no-svg .mfp-content .mfp-close {
    background: url("/images/global/lightbox_close-btn.png") no-repeat;
}
.mfp-content #popupContainer, .mfp-content .popupContainer, .mfp-content .mfp-iframe-scaler iframe, .white-bg #popupContainer {
    background: #fff;
}
.mfp-content #popupContainer, .mfp-content .popupContainer, .mfp-content .mfp-iframe-scaler iframe { 
    border-bottom: 10px solid #009dd2; 
} 
.mfp-content > .popupContainer {
    margin: 0 auto !important;
    top: 0 !important;
    padding: 0 !important;
}

.mfp-content #popupContainer {
    margin: 0 auto;
    overflow: inherit;
    position: relative;
    max-width: 960px;
}
.mfp-content #popupContainer.onesource {
    border-top: 10px solid #c89618;
    border-bottom: 10px solid #ebd59d;
}
.mfp-content #popupContent {
    margin: 0;
}
.mfp-content #popupTitleArea {
    background-image: none;
    padding: 10px 15px;
}
#popupTitleArea > .seawitch {
    display: none;
}
#popupTitleArea > .title {
    margin: 0;
}
.mfp-content #popupTitleArea > .title {
    display: inline-block;
    vertical-align: middle;
}
/* makeRequest confirm modal*/
.confirm-modal .mfp-content {
    width: auto;
}
/* modal for local-alert */
.local-alert .mfp-content {
    max-width: 480px;
}

@media screen and (max-width: 990px) {
    .mfp-close-inner-wrapper {
        margin-left: 15px;
        margin-right: 15px;
    }
    .mfp-content > #popupContainer .mfp-close {
        right: -10px;
    }
}
@media screen and (max-width: 767px) {
    /* Delete
    .mfp-content #popupTitleArea > .seawitch {
        display: none;
    }
    */ 
    .mfp-content #popupTitleArea > .title {
        display: block;
        margin: 5px;
    }
}
@media print {
    .mfp-bg, .mfp-close-wrapper, .mfp-content #popupTitleArea > .seawitch, .mfp-wrap ~ #container {
        display: none;
    }
    .mfp-wrap, .mfp-container {
        height: auto;
        padding: 0;
        position: relative;
    }
    .mfp-content #popupTitleArea > .title {
        display: block;
        margin: 5px;
    }
}
