/*
 * Princess Specific Styles for qTip2 - Pretty powerful tooltips - v2.2.1
 */

/* Princess Tooltip     */
.ui-tooltip-princess {
    background-color: #FFF;
    border-color: #333;
    color: #000;
    border: none;
    font-size: 1em;
    line-height: 1.3846em;
	max-width: 400px;
    min-width: 150px;
    -webkit-box-shadow: 0 3px 5px 0 rgba(0,0,0,.5);
	box-shadow: 0 3px 5px 0 rgba(0,0,0,.5);
}
.ui-tooltip-princess .qtip-content {
	padding: 8px 10px;
}
.ui-tooltip-princess .qtip-titlebar  {
	background-color: #0054A0;
	color: #fff;
	font-size: 1.3846em;/*18*/
	font-weight: 700;
	line-height: 1.3846em;
	padding: 5px 35px 5px 10px;
}
.qtip-titlebar .regular {
	font-weight: 400;
}
.ui-tooltip-princess .ui-tooltip-titlebar .titlebar-premium {
	color: #fcde00;
}
/* Princess Tooltip - Red */
.tooltip-red {
	border-color: #db0000; 
}
.tooltip-red .qtip-titlebar{
	background-color: #db0000;
}
/* Princess Tooltip - Green */
.tooltip-green {
	border-color: #69952f; 
}
.tooltip-green .qtip-titlebar {
	background-color: #69952f;
}
/* Princess Tooltip - Blue */
.tooltip-blue {
	border-color: #3876c1; 
}
.tooltip-blue .qtip-titlebar{
	background-color: #3876c1;
}
/* Princess Tooltip - Purple */
.tooltip-purple {
	border-color: #845aa5; 
}
.tooltip-purple .qtip-titlebar {
	background-color: #845aa5;
}	
/* Princess Tooltip - Gray */
.tooltip-gray {
	border-color: #4d4d4d; 
}
.tooltip-gray .qtip-titlebar{
	background-color: #e6e6e6;
	color: #000;
}